'use client';

import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect } from 'react';
import HeaderImage from './../../public/assets/images/home_header.png';
import { useAccountStore } from '@/lib/client/store/account.store';
import { useUser } from '@/modules/user/user.queries';
import { Logo } from '@/components/icons/logo';
import { Button } from '@/components/ui/button';
import Link from 'next/link';

export default function Home() {
  const router = useRouter();
  const { data: user } = useUser();
  const { type: accountType, isLoading } = useAccountStore();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!user) {
      return;
    }

    if (!accountType) {
      return router.push(`/onboarding/mode`);
    }

    if (accountType === 'business') {
      return router.push(`/app/my-jobs`);
    }

    if (accountType === 'candidate') {
      router.push(`/app/explore`);
    }
  }, [isLoading, accountType, user, router]);

  const onClick = useCallback(() => {
    if (!user) {
      return router.push(`/auth/sign-in`);
    }

    if (accountType === 'business') {
      return router.push(`/app/my-jobs`);
    }

    if (accountType === 'candidate') {
      return router.push(`/app/explore`);
    }
  }, [user, router]);

  return (
    <main className='bg-brand-accent'>
      <div className='min-h-[calc(100svh-80px)] container max-w-6xl flex flex-col items-center justify-between gap-6 py-8'>
        <div />

        <div className='flex flex-col items-center space-y-4'>
          <Image
            src={HeaderImage}
            alt='Jobreel header image'
            aria-label='Group of people in bubbles'
            className='aspect-square w-56 xs:w-72 sm:w-72 lg:w-80 xl:w-96'
          />

          <Logo className='w-56 xs:w-72 sm:w-72 lg:w-80 h-auto fill-zinc-50' />
        </div>

        <div className='space-y-4'>
          <Button
            variant='brand'
            className='w-full font-semibold'
            onClick={onClick}
          >
            Continue with Phone Number
          </Button>

          <p className='text-xs text-zinc-50 text-center'>
            By tapping{' '}
            <span className='font-semibold'>&quot;Continue&quot;</span>, you
            agree to our{' '}
            <Link
              href='https://jobreel.io/tos'
              target='_blank'
              rel='noopener noreferrer'
              className='font-semibold hover:underline hover:underline-offset-1 text-zinc-50'
            >
              Terms of service
            </Link>{' '}
            and agree you are at least 18 years of age. Learn how we process
            your data in our{' '}
            <Link
              href='https://jobreel.io/privacy'
              target='_blank'
              rel='noopener noreferrer'
              className='font-semibold hover:underline hover:underline-offset-1 text-zinc-50'
            >
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link
              href='https://jobreel.io/privacy'
              target='_blank'
              rel='noopener noreferrer'
              className='font-semibold hover:underline hover:underline-offset-1 text-zinc-50'
            >
              Cookies Policy
            </Link>
          </p>
        </div>
      </div>
    </main>
  );
}
